import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { useQuery } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { 
  Stack, 
  Paper, 
  Box,
  Typography,
  Button,
  LinearProgress,
  List,
  Skeleton,
  Menu,
  MenuItem,
  Checkbox,
  ListItem,
  ListItemText,
  IconButton,
  Chip
} from '@mui/material'
import StyledAlert from '@/components/StyledAlert'
import { ReactComponent as BackLeftArrowIcon } from '@/assets/backLeftArrow.svg'
import { ReactComponent as BackRightArrowIcon } from '@/assets/backRightArrow.svg'
import { ReactComponent as ExportIcon } from '@/assets/export.svg'
import { ReactComponent as IncreaseIcon } from '@/assets/increase.svg'
import { ReactComponent as DecreaseIcon } from '@/assets/decrease.svg'
import { ReactComponent as CopyIcon } from '@/assets/copy.svg'
import { ReactComponent as SelectDownIcon } from '@/assets/selectDown.svg'
import { ReactComponent as CheckDefaultIcon } from '@/assets/checkDefault.svg'
import { ReactComponent as CheckSelectedIcon } from '@/assets/checkSelected.svg'
import { ReactComponent as CheckPartialIcon } from '@/assets/checkPartial.svg'
import { ReactComponent as SubCategoryOpenIcon } from '@/assets/subCategoryOpen.svg'
import { ReactComponent as TagCategoryIcon } from '@/assets/tagCategory.svg'
import { ReactComponent as TagDeleteIcon } from '@/assets/tagDelete.svg'
import { ReactComponent as TagExpandIcon } from '@/assets/tagExpand.svg'
import { ReactComponent as TagCollapseIcon } from '@/assets/tagCollapse.svg'
import { ReactComponent as DataEmptyIcon } from '@/assets/dataEmpty.svg'
import { ReactComponent as ExcelExportIcon } from '@/assets/excelExport.svg'
import { ReactComponent as CsvExportIcon } from '@/assets/csvExport.svg'

import { getSummaryDetail } from '@/api/getSummaryAPI'
import { getReportSummaryExcelData, getReportSummaryCsvData } from '@/api/getReportDataAPI'
import UseSubCategoryStore from "@/hooks/UseSubCategoryStore";
import UseCaseDataStore from "@/hooks/UseCaseDataStore";

const useHandleQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CustomSkeleton = () => {
  const skeletonColumns = Array.from({ length: 6 }).map((_, index) => (
    <Skeleton 
      key={index}
      variant="rectangular" 
      sx={{
        width: 'calc(100% / 3 - 12px)',
        height: '400px',
        margin: '20px auto',
        borderRadius: '20px',
        backgroundColor: '#bbb'
      }}
      animation={index % 2 === 0 ? 'pulse' : 'wave'}
      animationspeed={index % 2 === 0 ? 'fast' : 'slow'}
    />
  ));

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '12px',
        width: '100%'
      }}
    >
      {skeletonColumns}
    </Box>
  );
};

function ReportContent() {
  const [ summaryDetail, setSummaryDetail ] = useState({})
  const [ categoryAnchorEl, setCategoryAnchorEl ] = useState(null);
  const [ subCategoryMenuAnchorEl, setSubCategoryMenuAnchorEl ] = useState(null);
  const [ currentCategory, setCurrentCategory ] = useState('');
  const [ selectedTags, setSelectedTags ] = useState([]);
  const [ categoriesData, setCategoriesData ] = useState([]);
  const [ tagAreaExpand, setTagAreaExpand ] = useState(true);
  const [ triggerExportExcel, setTriggerExportExcel ] = useState(false);
  const [ triggerExportCsv, setTriggerExportCsv ] = useState(false);
  const [ triggerFromOtherPage, setTriggerFromOtherPage ] = useState(true);
  const [ hasDownloadedExcel, setHasDownloadedExcel ] = useState(false);
  const [ hasDownloadedCsv, setHasDownloadedCsv ] = useState(false);
  const [ copySuccess, setCopySuccess ] = useState(false);
  const [ copySuccessMsg, setCopySuccessMsg ] = useState('');
  const [ reportMenuAnchorEl, setReportMenuAnchorEl ] = useState(null);

  const categoryMenuOpen = Boolean(categoryAnchorEl);
  const subCategoryMenuOpen = Boolean(subCategoryMenuAnchorEl);
  const reportMenuOpen = Boolean(reportMenuAnchorEl);

  const ListItemTags = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
    display: 'inline-block'
  }));

  const { subCategoriesSelected, updateMainCategorySelected, updateSubCategorySelected, deleteSubCategorySelected, clearSubCategorySelected } = UseSubCategoryStore()
  const { caseData } = UseCaseDataStore()

  const { weekParam } = useParams()
  const year = weekParam?.split('-')[0]
  const week = weekParam?.split('W')[1]
  const query = useHandleQuery();
  const startDate = query.get('startDate');
  const endDate = query.get('endDate');
  const previousDate = DateTime.fromFormat(startDate, 'yyyy/MM/dd').minus({ days: 1 }).toFormat('yyyy/MM/dd')
  const previousSevenDate = DateTime.fromFormat(startDate, 'yyyy/MM/dd').minus({ days: 7 }).toFormat('yyyy/MM/dd')
  const nextDate = DateTime.fromFormat(endDate, 'yyyy/MM/dd').plus({ days: 1 }).toFormat('yyyy/MM/dd')
  const nextSevenDate = DateTime.fromFormat(endDate, 'yyyy/MM/dd').plus({ days: 7 }).toFormat('yyyy/MM/dd')
  const isPreviousWeek = DateTime.now().minus({ days: 7 }) >= DateTime.fromFormat(startDate, 'yyyy/MM/dd').startOf('day') && DateTime.now().minus({ days: 7 }) <= DateTime.fromFormat(endDate, 'yyyy/MM/dd').endOf('day')

  const { data: summaryDetailData, isLoading: isLoadingSummaryDetailData, isError: isErrorSummaryDetailData, refetch: refetchSummaryDetailData } = useQuery({
    queryFn: () => getSummaryDetail(year, week, '', subCategoriesSelected),
    queryKey: ['summaryDetailData', year, week],
    refetchOnWindowFocus: false,
    staleTime: 60000
  })

  const { data: reportSummaryExcelData, refetch: refetchReportSummaryExcelData } = useQuery({
    queryFn: () => getReportSummaryExcelData(year, week, ''),
    enabled: false,
    queryKey: ['reportSummaryExcelData', year, week],
    refetchOnWindowFocus: false,
  })

  const { data: reportSummaryCsvData, refetch: refetchReportSummaryCsvData } = useQuery({
    queryFn: () => getReportSummaryCsvData(year, week, ''),
    enabled: false,
    queryKey: ['reportSummaryCsvData', year, week],
    refetchOnWindowFocus: false,
  })

  const showReportMenu = (event) => {
    setReportMenuAnchorEl(event.currentTarget);
  };

  const handleReportMenuClose = () => {
    setReportMenuAnchorEl(null);
  };

  const transformData = (data, year, week) => {
    const result = [];
    const yearData = data[year];
    if (!yearData) return result;
    
    const weekData = yearData[week];
    if (!weekData) return result;
    
    for (const mainCategory in weekData) {
      const subCategories = weekData[mainCategory];
      for (const subCategory in subCategories) {
        const subCategoryData = subCategories[subCategory];
        result.push({
          mainCategory,
          subCategory,
          summary: subCategoryData.summary,
          case_count_current_period: subCategoryData.case_count_current_period,
          case_count_growth_rate: subCategoryData.case_count_growth_rate,
          case_count_percentage: subCategoryData.case_count_percentage
        });
      }
    }
    
    return result;
  }

  // 點擊開啟主案類 Menu
  const showCategoryMenu = (event) => {
    setCategoryAnchorEl(event.currentTarget);
  };

  // 點擊顯示次案類 Menu
  const showSubCategoryMenu = (event, category) => {
    setSubCategoryMenuAnchorEl(event.currentTarget);
    setCurrentCategory(category);
  };

  // 關閉案件分類選單
  const handleCategoryMenuClose = () => {
    setCategoryAnchorEl(null);
    setSubCategoryMenuAnchorEl(null);
    setCurrentCategory('')
  };

  // 處理主案類的選取狀態
  const handleCategoryChange = (category, isChecked) => {
    updateMainCategorySelected(isChecked, category, caseData)
  };

  // 處理次案類的選取狀態
  const handleSubCategoryChange = (category, subCategory, isChecked) => {
    updateSubCategorySelected(category, subCategory, isChecked)
  };

  // 主案類的 checkbox 狀態判斷
  const isCategoryChecked = (category) => {
    const subCategories = caseData?.categories[category] || [];
    const selectedSubCategories = subCategoriesSelected[category] || [];
    if (selectedSubCategories.length === subCategories.length) return 'all';
    if (selectedSubCategories.length > 0) return 'partial';
    return 'none';
  };

  // 刪除 tags
  const handleDeleteTag = (chipToDelete) => () => {
    setSelectedTags((prev) => prev.filter((item) => item.tag !== chipToDelete));
    deleteSubCategorySelected(chipToDelete)
  };

  // 清空已選的 tags
  const handleResetTags = () => {
    setSelectedTags([])
    clearSubCategorySelected()
  }

  // 篩選器查詢
  const handleSearch = () => {
    refetchSummaryDetailData()
  }

  // 複製摘要文字
  const handleCopy = (summary) => {
    const textToCopy = summary.join('\n')
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopySuccess(true)
      setCopySuccessMsg('文字已複製到剪貼板')
    }).catch(err => {
      setCopySuccess(false)
      // console.error('複製失敗', err);
    });
  };

  useEffect(() => {
    if (summaryDetailData) {
      // console.log('summaryDetailData', summaryDetailData.data.data);
      setSummaryDetail(summaryDetailData.data.data)
    }
  }, [summaryDetailData])

  useEffect(() => {
    if (caseData && caseData.responseData) {
      setCategoriesData(Object.keys(caseData.categories));
    }    
  }, [caseData]);

  if (isErrorSummaryDetailData) {
    refetchSummaryDetailData()
  }

  // 將所有已選取的各類 tags 合併
  useEffect(() => {
    // 因多個次案類皆有'其他'，須另外加上其主案類名稱一同顯示
    const selectedSubCategoryTags = Object.entries(subCategoriesSelected).map(([key, value]) => {
      return value.map(item => {
        if (item === '其他') {
          return item.replace('其他', `${key}/其他`)
        }
        return item
      })
    }).flat()

    const transformSubCategoryTags = [...selectedSubCategoryTags].map(item => {
      return { type: 'category', tag: item }
    })

    setSelectedTags([...transformSubCategoryTags]);
  }, [subCategoriesSelected])

  // 智慧摘要 Excel 匯出
  useEffect(() => {
    if (triggerExportExcel) {
      refetchReportSummaryExcelData()
      setTriggerExportExcel(false)
    }

    if (reportSummaryExcelData && !triggerFromOtherPage && !hasDownloadedExcel) {
      try {
        const url = window.URL.createObjectURL(reportSummaryExcelData);
        const a = document.createElement('a');
        a.href = url;
        a.download = `report-summary-${weekParam}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setHasDownloadedExcel(true)
      } catch (err) {
        console.error(err);
      }
    }
  }, [triggerExportExcel, triggerFromOtherPage, reportSummaryExcelData, hasDownloadedExcel]);

  // 智慧摘要 Csv 匯出
  useEffect(() => {
    if (triggerExportCsv) {
      refetchReportSummaryCsvData()
      setTriggerExportCsv(false)
    }

    if (reportSummaryCsvData && !triggerFromOtherPage && !hasDownloadedCsv) {
      try {
        const url = window.URL.createObjectURL(reportSummaryCsvData);
        const a = document.createElement('a');
        a.href = url;
        a.download = `report-summary-${weekParam}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setHasDownloadedCsv(true)
      } catch (err) {
        console.error(err);
      }
    }
  }, [triggerExportCsv, triggerFromOtherPage, reportSummaryCsvData, hasDownloadedCsv]);

  useEffect(() => {
    setTriggerFromOtherPage(true)
  }, [])

  // 複製摘要文字訊息提醒
  useEffect(() => {
		if (copySuccessMsg) {
			setTimeout(() => {
				setCopySuccess(false);
				setCopySuccessMsg("");
			}, 2000);

			setCopySuccess(true);
		}
	}, [copySuccessMsg]);

  return (
    <Paper 
      sx={{
        m: 2,
        borderRadius: '16px',
        height: 'auto',
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.05)'
      }}
    >
      <Stack
        sx={{
          pt: '20px',
          pb: '22px',
          borderBottom: (theme) => `1px solid ${theme.palette.neutral.light}`
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              pl: '16px',
              mr: '16px',
              borderLeft: '4px solid #52697A',
              fontWeight: 400
            }}
          >
            智慧摘要分析
          </Typography>

          {/* 篩選案件分類 */}
          <Stack
            sx={{
              flex: 1
            }}
          >
            <Button 
              onClick={showCategoryMenu}
              sx={{
                width: '100%',
                background: '#FFFFFF',
                border: '1px solid #E5E5E5',
                color: '#737373',
                padding: '12px 20px',
                fontWeight: 400,
                borderRadius: '8px',
                justifyContent: 'space-between',
                '&:hover': {
                  background: '#FAFAFA'
                },
                '&:focus': {
                  background: '#FFFFFF',
                  border: '1px solid #52697A'
                }
              }}
            >
              <span>選擇案件分類</span>
              <SelectDownIcon fill="#737373" />
            </Button>
            <Menu
              anchorEl={categoryAnchorEl}
              open={categoryMenuOpen}
              onClose={handleCategoryMenuClose}
              sx={{
                '& .MuiPaper-root': {
                  marginTop: '5px',
                  position: 'absolute',
                  left: '0px',
                  top: '0px',
                  borderRadius: '8px',
                  width: '660px',
                  height: '480px',
                  border: '1px solid #52697A',
                  '& .MuiList-root': {
                    display: 'flex',
                    flexFlow: 'row wrap',
                    padding: 0
                  },
                  overflow: 'hidden'
                }
              }}
            >
              <Stack
                sx={{
                  width: '330px', 
                  height: '480px',
                  padding: '8px 0',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  borderRight: '1px solid #ccc',
                  boxShadow: '10px 0 20px 0 rgba(0, 0, 0, .05)',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '10px',
                    marginTop: '1px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#D4D4D4',
                    borderRadius: '10px',
                  },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    p: '4px 0 4px 16px',
                    mr: '16px',
                    borderLeft: '4px solid #52697A',
                    fontWeight: 400
                  }}
                >
                  主案類
                </Typography>
                {categoriesData.map((category) => {
                  const checkedStatus = isCategoryChecked(category)
                  return (
                    <MenuItem 
                      key={category}
                      sx={{
                        padding: '3px 10px',
                        margin: '2px 0',
                        '& .MuiTypography-root': {
                          fontWeight: 400,
                          fontSize: '14px',
                          lineHeight: '21px',
                          letterSpacing: '1px',
                          color: (theme) => theme.palette.neutral.silver
                        }
                      }}
                      onMouseOver={(event) => showSubCategoryMenu(event, category)}
                    >
                      <Checkbox
                        checked={checkedStatus === 'all'}
                        indeterminate={checkedStatus === 'partial'}
                        icon={<CheckDefaultIcon />}
                        checkedIcon={<CheckSelectedIcon />}
                        indeterminateIcon={<CheckPartialIcon />}
                        onChange={(event) => {
                          handleCategoryChange(category, event.target.checked);
                        }}
                      />
                      <ListItemText 
                        primary={category} 
                        sx={{
                          whiteSpace: 'pre-line',
                          '& .MuiTypography-root': {
                            fontWeight: checkedStatus === 'all' || checkedStatus === 'partial' ? 'bold' : 'normal'
                          }
                        }}
                      />
                        <IconButton
                          onClick={(event) => showSubCategoryMenu(event, category)}
                        >
                          <SubCategoryOpenIcon fill={`${checkedStatus === 'all' || checkedStatus === 'partial' ? '#52697A' : '#D4D4D4'}`} />
                        </IconButton>
                    </MenuItem>
                  )
                })}
              </Stack>

              <Stack
                sx={{
                  width: 'calc(100% - 330px)',
                  height: '480px',
                  padding: '8px 0',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '10px',
                    marginTop: '1px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#D4D4D4',
                    borderRadius: '10px',
                  },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    p: '4px 0 4px 16px',
                    mr: '16px',
                    borderLeft: '4px solid #52697A',
                    fontWeight: 400
                  }}
                >
                  次案類
                </Typography>
                {subCategoryMenuOpen && currentCategory && (
                  <>
                    {caseData?.categories[currentCategory].map((subCategory) => (
                      <MenuItem 
                        key={subCategory} 
                        value={subCategory}
                        sx={{
                          padding: '3px 10px',
                          margin: '2px 0',
                          '& .MuiTypography-root': {
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '21px',
                            letterSpacing: '1px',
                            color: (theme) => theme.palette.neutral.silver
                          }
                        }}
                      >
                        <Checkbox
                          checked={subCategoriesSelected[currentCategory]?.includes(subCategory) || false}
                          icon={<CheckDefaultIcon />}
                          checkedIcon={<CheckSelectedIcon />}
                          onChange={(event) => {
                            handleSubCategoryChange(currentCategory, subCategory, event.target.checked)
                          }}
                        />
                        <ListItemText 
                          primary={subCategory} 
                          sx={{
                            whiteSpace: 'pre-line'
                          }}
                        />
                      </MenuItem>
                    ))}
                  </>
                  )
                }
              </Stack>
            </Menu>
          </Stack>

          <Button
            onClick={handleSearch}
            sx={{
              width: 'auto',
              background: '#D7DFE4',
              border: '1px solid transparent',
              color: '#0A0A0A',
              padding: '8px 20px',
              margin: '4px 8px',
              fontWeight: 400,
              borderRadius: '99px',
              justifyContent: 'space-between',
              '&:hover': {
                background: '#CFD8DF',
              },
              '&:focus': {
                background: '#D7DFE4',
                border: '1px solid #ACBCC8'
              }
            }}
          >
            查詢
          </Button>
        </Box>

        {/* 已選取標籤區塊 */}
        {selectedTags.length !== 0 &&
          <Stack
            sx={{
              m: '8px 20px 0 20px',
              pr: '30px',
              backgroundColor: '#F5F5F5',
              borderRadius: '4px',
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
              position: 'relative',
              transition: 'all .3s',
              height: tagAreaExpand ? 'auto' : '40px',
              overflowY: tagAreaExpand ? 'unset' : 'hidden'
            }}
          >
            {selectedTags?.map((item) => {
              let icon
              if (item.type === 'category') {
                icon = <TagCategoryIcon fill='#E95076' />
              }

              return (
                <ListItemTags key={item.tag}>
                  <Chip
                    icon={icon}
                    deleteIcon={<TagDeleteIcon fill='#737373' />}
                    label={item.tag}
                    onDelete={handleDeleteTag(item.tag)}
                    sx={{
                      p: '4px 8px',
                      backgroundColor: '#FDEDF1',
                      borderRadius: '4px',
                      border: '1px solid #F4A4B8',
                      fontSize: '14px',
                      lineHeight: '21px',
                      fontWeight: 'bold',
                      transition: 'all .3s',
                      '&.MuiChip-root': {
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'center',
                        transition: 'all .3s',
                        svg: {
                          margin: 0
                        },
                        span: {
                          padding: '0 4px'
                        },
                        '&:hover': {
                          border: '1px solid #E92153',
                          svg: {
                            fill: '#E92153'
                          }
                        }
                      },
                    }}
                  />
                </ListItemTags>
              )
            })}
            <Button
              sx={{
                width: 'auto',
                background: '#FAFAFA',
                color: '#E95050',
                padding: '4px 8px',
                fontWeight: 400,
                borderRadius: '4px',
                justifyContent: 'space-between',
                '&:hover': {
                  background: '#FAFAFA'
                },
              }}
              onClick={handleResetTags}
            >
              全部重設
            </Button>
            <Box
              sx={{
                position: 'absolute',
                top: '4px',
                right: '4px',
                cursor: 'pointer'
              }}
              onClick={() => setTagAreaExpand(!tagAreaExpand)}
            >
              {
                tagAreaExpand ? <TagCollapseIcon /> : <TagExpandIcon />
              }
            </Box>
          </Stack>
        }
      </Stack>
      <Stack
        sx={{
          backgroundColor: '#F0F3F5',
          p: '12px 20px',
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Link 
          to={`/dashboard/smart-summary`}
          style={{
            textDecoration: 'none',
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
          }}
        >
          <BackLeftArrowIcon fill="#0A0A0A" />
          <Typography
            variant="h5"
            sx={{
              fontWeight: 400,
              color: (theme) => theme.palette.neutral.silver,
              pl: '4px'
            }}
          >
            返回報告列表
          </Typography>
        </Link>
        <Box>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              textAlign: 'center'
            }}
          >
            周報告{weekParam} 
          </Typography>
          <Typography
            sx={{
              color: theme => theme.palette.neutral.dark,
              fontSize: '10px',
              lineHeight: '15px',
              textAlign: 'center',
              letterSpacing: '.05rem'
            }}
          >
            {startDate} ~ {endDate}
          </Typography>
        </Box>
        <Button
          onClick={showReportMenu}
          sx={{
            width: 'auto',
            background: '#FFFFFF',
            border: '1px solid #ACBCC8',
            color: '#0A0A0A',
            padding: '8px 20px',
            fontWeight: 'bold',
            borderRadius: '99px',
            '&:hover': {
              background: '#F0F3F5',
            },
            '&:focus': {
              background: '#F0F3F5',
              border: '1px solid #52697A'
            }
          }}
        >
          <span style={{ marginRight: '4px' }}>匯出</span>
          <ExportIcon fill='#0A0A0A' />
        </Button>
        <Menu
          anchorEl={reportMenuAnchorEl}
          open={reportMenuOpen}
          onClose={handleReportMenuClose}
          sx={{
            '& .MuiPaper-root': {
              marginTop: '5px',
              backgroundColor: 'transparent',
              p: 0,
              borderRadius: '99px',
              height: 'auto',
              '& .MuiList-root': {
                padding: 0,
                display: 'flex',
                li: {
                  p: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  }
                }
              },
            }
          }}
        >
          {['EXCEL', 'CSV'].map((option) => (
            <MenuItem 
              key={option}
            >
              <Button 
                sx={{
                  backgroundColor: 'white',
                  color: '#0A0A0A',
                  fontWeight: 'normal',
                  p: '8px 20px',
                  width: '100%',
                  borderRadius: option === 'EXCEL' ? '99px 0 0 99px' : '0 99px 99px 0',
                  border: '1px solid #ACBCC8',
                  '&:hover': {
                    backgroundColor: '#CFD8DF',
                  },
                  '&:focus': {
                    backgroundColor: '#CFD8DF',
                  },
                }}
                onClick={() => {
                  if (option === 'CSV') {
                    setTriggerExportCsv(true)
                    setTriggerFromOtherPage(false)
                    setHasDownloadedCsv(false)
                  } else {
                    setTriggerExportExcel(true)
                    setTriggerFromOtherPage(false)
                    setHasDownloadedExcel(false)
                  }
                }}
              >
                {option === 'EXCEL' ? 
                  <ExcelExportIcon />
                  : 
                  <CsvExportIcon width={20} height={20} />
                }
                <Typography
                  variant='h5'
                  sx={{
                    ml: '4px',
                    fontWeight: 400
                  }}
                >
                  {option}
                </Typography>
              </Button>
            </MenuItem>
          ))}
        </Menu>
      </Stack>
      <Stack
        sx={{
          p: '20px 14px',
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'flex-start',
          gap: '12px',
          height: 'calc(100vh - 340px)',
          overflowY: 'auto'
        }}
      >
        {isLoadingSummaryDetailData && summaryDetail && <CustomSkeleton />}

        {/* 無搜尋結果 */}
        {Object.values(summaryDetail).length === 0 && !isLoadingSummaryDetailData && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexFlow: 'column wrap',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              overflowY: 'hidden'
            }}
          >
            <DataEmptyIcon />
            <Typography 
              variant='h3'
              sx={{
                color: '#52697A',
                fontWeight: 'bold',
                textAlign: 'center',
                mt: '12px'
              }}
            >
              無搜尋結果
            </Typography>
          </Box>
        )}

        {/* 有搜尋結果 */}
        {
          !isLoadingSummaryDetailData && transformData(summaryDetail, year, week)?.map((item, index) => (
            <Box 
              key={index}
              sx={{
                width: 'calc(100% / 3 - 12px)',
                display: 'flex',
                flexFlow: 'column wrap',
              }}
            >
              <Box
                sx={{
                  backgroundColor: theme => theme.palette.bg.grey,
                  borderRadius: '16px 16px 0 0',
                  p: '20px',
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box 
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-end'
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme => theme.palette.info.main,
                        fontWeight: 'bold',
                        fontSize: '40px',
                        lineHeight: '52px',
                        mt: '8px',
                        mr: '8px'
                      }}
                    >
                      {`${(item.case_count_percentage * 100).toFixed(1)}%`}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {item.case_count_growth_rate > 0 ? <IncreaseIcon /> : item.case_count_growth_rate === 0 ? null : <DecreaseIcon />}
                      <Typography
                        variant='h4'
                        sx={{
                          ml: '4px',
                          mb: item.case_count_growth_rate === 0 ? '10px' : 0,
                          color: item.case_count_growth_rate > 0 ? '#E95050' : item.case_count_growth_rate === 0 ? '#52697A' : '#23BFAA'
                        }}
                      >
                        {`${item.case_count_growth_rate === 0 ? '--' : item.case_count_growth_rate > 0 ? `${Math.round(item.case_count_growth_rate * 100)}%` : `${Math.round(Math.abs(item.case_count_growth_rate) * 100)}%`}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    variant='h5'
                    sx={{
                      color: theme => theme.palette.info.main,
                      fontWeight: 700,
                      mb: '5px'
                    }}
                  >
                    共 {item.case_count_current_period.toLocaleString()} 件
                  </Typography>
                </Box>
                <LinearProgress 
                  variant="determinate" 
                  value={(item.case_count_percentage) * 100} 
                  sx={{
                    backgroundColor: theme => theme.palette.bg.dark,
                    width: '100%',
                    height: '12px',
                    mb: '12px',
                    borderRadius: '99px',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: theme => theme.palette.info.main
                    }
                  }}
                />
                <Typography 
                  variant="h4"
                  sx={{
                    fontWeight: 400,
                    color: theme => theme.palette.neutral.dark
                  }}
                >
                  {item.subCategory}
                </Typography>
                <Typography 
                  variant='h5'
                  sx={{
                    color: theme => theme.palette.info.main,
                    fontWeight: 700,
                  }}
                >
                  {item.mainCategory}
                </Typography>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '24px',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                  onClick={() => handleCopy(item.summary)}
                >
                  <CopyIcon />
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: theme => theme.palette.bg.light,
                  borderRadius: '0 0 16px 16px',
                  p: '20px',
                  flex: 1
                }}
              >
                <Typography
                  variant='h5'
                  sx={{
                    color: '#52697A',
                    fontWeight: 'bold'
                  }}
                >
                  重點摘要
                </Typography>
                <List
                  sx={{
                    position: 'relative'
                  }}
                >
                  {
                    item.summary.map(((text, index) => (
                      <ListItem 
                        key={index}
                        sx={{
                          p: '0 0 0 1.5em',
                          '&:before': {
                            content: '""',
                            width: '4px',
                            height: '4px',
                            position: 'absolute',
                            backgroundColor: '#000',
                            top: '10px',
                            left: '10px',
                            borderRadius: '50%',
                          }
                        }}
                      >
                        <Typography
                          variant='h5'
                          sx={{
                            fontWeight: 400
                          }}
                        >
                          {text}
                        </Typography>
                      </ListItem>
                    )))
                  }
                </List>
              </Box>
            </Box>
          ))
        }
      </Stack>
      <Stack
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.neutral.light}`,
          p: '20px',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '32px',
            m: '4px'
          }}
        >
          <Link
            to={`/dashboard/smart-summary/${year}-W${week - 1}?startDate=${previousSevenDate}&endDate=${previousDate}`}
            style={{
              textDecoration: 'none',
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
              pointerEvents: Number(week) === 23 ? 'none' : 'auto'
            }}
          >
            <BackLeftArrowIcon fill={`${Number(week) === 23 ? '#CCC' : '#0A0A0A'}`} />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 400,
                color: (theme) => `${Number(week) === 23 ? '#CCC' : `${theme.palette.neutral.dark}`}`,
                pl: '4px'
              }}
            >
              上一則
            </Typography>
          </Link>
          <Link
            to={`/dashboard/smart-summary/${year}-W${Number(week) + 1}?startDate=${nextDate}&endDate=${nextSevenDate}`}
            style={{
              textDecoration: 'none',
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
              pointerEvents: isPreviousWeek ? 'none' : 'auto'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 400,
                color: (theme) => `${isPreviousWeek ? '#CCC' : `${theme.palette.neutral.dark}`}`,
                pr: '4px'
              }}
            >
              下一則
            </Typography>
            <BackRightArrowIcon fill={`${isPreviousWeek ? '#CCC' : '#0A0A0A'}`} />
          </Link>
        </Box>
      </Stack>

      {/* copy message alert */}
			{copySuccess && (
				<StyledAlert severity="success">{copySuccessMsg}</StyledAlert>
			)}
    </Paper>
  );
}

export default ReportContent;
import axiosInstance from '@/api/axiosConfig';

export const login = async (account, password) => {
  const response = await axiosInstance.post('/api/auth/token/', { 
    "account": account,
    "password": password
  });
  const { access } = response.data.data;
  localStorage.setItem('accessToken', access);
  return response.data;
};


  import React, { useEffect, useState, useCallback } from 'react'
  import { Link, useLocation  } from 'react-router-dom'
  import styled from '@emotion/styled'
  import { useQuery } from '@tanstack/react-query'
  import { 
    Stack, 
    Paper, 
    Box, 
    Typography, 
    Tabs, 
    Tab, 
    Pagination, 
    TextField, 
    Button, 
    Skeleton, 
    IconButton, 
    MenuItem,
    Checkbox,
    ListItemText,
    Menu,
    Chip,
    FormControlLabel,
    CircularProgress
  } from '@mui/material'
  import { DataGrid } from '@mui/x-data-grid';
  import { getCases, getCasesFirst, clearFetchedChunks } from '@/api/getCasesAPI'
  import { getWordCloud, getPreviousWordCloud } from '@/api/getWordCloudAPI'
  import { getReportCasesExcelData } from '@/api/getReportDataAPI'
  import UseDateRangeStore from "@/hooks/UseDateRangeStore";
  import UseTableDataStore from "@/hooks/UseTableDataStore";
  import UseOwnerUnitStore from "@/hooks/UseOwnerUnitStore";
  import UseSubCategoryStore from "@/hooks/UseSubCategoryStore";
  import UseCaseDataStore from "@/hooks/UseCaseDataStore";
  import UseSearchStore from "@/hooks/UseSearchStore";
  import UseSatisfactionStore from '@/hooks/UseSatisfactionStore'
  import { transformChartDate } from '@/utils/transformChartDate'
  import { previousColors, currentColors } from '@/utils/wordCloudColors'
  import { ReactComponent as SortDefaultIcon } from '@/assets/sortDefault.svg'
  import { ReactComponent as SortUpIcon } from '@/assets/sortUp.svg'
  import { ReactComponent as SortDownIcon } from '@/assets/sortDown.svg'
  import { ReactComponent as CheckDefaultIcon } from '@/assets/checkDefault.svg'
  import { ReactComponent as CheckSelectedIcon } from '@/assets/checkSelected.svg'
  import { ReactComponent as CheckPartialIcon } from '@/assets/checkPartial.svg'
  import { ReactComponent as SubCategoryOpenIcon } from '@/assets/subCategoryOpen.svg'
  import { ReactComponent as SelectDownIcon } from '@/assets/selectDown.svg'
  import { ReactComponent as TagOwnerUnitIcon } from '@/assets/tagOwnerUnit.svg'
  import { ReactComponent as TagCategoryIcon } from '@/assets/tagCategory.svg'
  import { ReactComponent as TagDeleteIcon } from '@/assets/tagDelete.svg'
  import { ReactComponent as TagExpandIcon } from '@/assets/tagExpand.svg'
  import { ReactComponent as TagCollapseIcon } from '@/assets/tagCollapse.svg'
  import { ReactComponent as CaseDetailIcon } from '@/assets/caseDetail.svg'
  import { ReactComponent as DataEmptyIcon } from '@/assets/dataEmpty.svg'
  import { ReactComponent as SearchIcon } from '@/assets/search.svg'
  import { ReactComponent as SearchIncludeIcon } from '@/assets/searchInclude.svg'
  import { ReactComponent as SearchExcludeIcon } from '@/assets/searchExclude.svg'
  import { ReactComponent as ExportIcon } from '@/assets/export.svg'
  import LineChart from '@/components/LineChart';
  import ColumnChart from '../components/ColumnChart'
  import WordCloudChart from '@/components/WordCloudChart';
  import StyledAlert from '@/components/StyledAlert'

  const ownerUnitMockData = {
    '教育局': ['人事室', '國中教育科', '政風室', '終身學習科'],
    '桃園區公所': ['公園管理課', '工務課', '民政課', '秘書室'],
    '楊梅區公所': ['工務課', '民政課', '社會課', '農經課'],
    '消防局': ['危險物品管理科', '火災預防科', '災害搶救科'],
    '社會局': ['人民團體科', '社會工作科', '社會救助科', '老人福利科'],
    '勞動局': ['勞動條件科', '秘書室', '綜合規劃科', '跨國勞動事務科'],
    '地政局': ['地價科', '地用科', '地籍科', '航空城開發科'],
    '就業職訓服務處': ['就業促進課', '就業安全課', '桃園就業服務站', '職訓推動課'],
    '桃園市立圖書館': ['中壢分館', '八德分館', '平鎮分館', '龜山分館'],
    '水務局': ['水利工程科', '水利行政科', '水利養護工程科', '河岸地工程管理科'],
    'a': ['就業促進課', '就業安全課', '桃園就業服務站', '職訓推動課'],
    'b': ['中壢分館', '八德分館', '平鎮分館', '龜山分館'],
    'c': ['水利工程科', '水利行政科', '水利養護工程科', '河岸地工程管理科']
  }
  

  const satisfactionSortOrder = {
    "非常不滿意": 1,
    "不滿意": 2,
    "普通": 3,
    "滿意": 4,
    "非常滿意": 5,
    "-": 6,
  };

  const satisfactionSortComparator = (v1, v2) => {
    return (satisfactionSortOrder[v1] || 6) - (satisfactionSortOrder[v2] || 6);
  };

  const columns = [
    { 
      field: 'id', 
      headerName: '#', 
      width: 82,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'record_time',
      headerName: '回報日期',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <div
          style={{ 
            display: 'flex',
            flexFlow: 'column wrap',
            alignItems: 'flex-start',
          }}
        >
          <span
            style={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 400,
              letterSpacing: '1px'
            }}
          >
            {row?.record_time?.split(' ')[0]}
          </span>
          <span
            style={{ 
              fontSize: '10px',
              lineHeight: '15px',
              fontWeight: 800,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              letterSpacing: '.5px'
            }}
          >
            {row?.record_time?.split(' ')[1]}
          </span>
        </div>
      ),
      sortable: true
    },
    {
      field: 'reply_time',
      headerName: '受理日期',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <div
          style={{ 
            display: 'flex',
            flexFlow: 'column wrap',
            alignItems: 'flex-start',
          }}
        >
          <span
            style={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 400,
              letterSpacing: '1px'
            }}
          >
            {row?.reply_time?.split(' ')[0] || '-'}
          </span>
          <span
            style={{ 
              fontSize: '10px',
              lineHeight: '15px',
              fontWeight: 800,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              letterSpacing: '.5px'
            }}
          >
            {row?.reply_time?.split(' ')[1] || '-'}
          </span>
        </div>
      ),
      sortable: true
    },
    {
      field: 'sub_category',
      headerName: '次案類',
      width: 170,
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      renderHeader: () => (
        <div 
          style={{ 
            display: 'flex',
            flexFlow: 'column wrap',
            alignItems: 'flex-start',
            justifyContent: 'center',
            fontSize: '14px',
            lineHeight: '21px', 
          }}
        >
          <span>次案類</span>
          <span 
            style={{ 
              color: '#E95076', 
              fontSize: '10px',
              lineHeight: '15px', 
            }}
          >
            主案類
          </span>
        </div>
      ),
      renderCell: ({ row }) => (
        <div
          style={{ 
            display: 'flex',
            flexFlow: 'column wrap',
            alignItems: 'flex-start',
          }}
        >
          <span
            style={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '130px'
            }}
          >
            {row.sub_category}
          </span>
          <span
            style={{ 
              color: '#5084E9',
              fontSize: '10px',
              lineHeight: '15px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '130px'
            }}
          >
            {row.main_category}
          </span>
        </div>
      ),
    },
    {
      field: 'owner_unit',
      headerName: '承辦機關單位',
      width: 164,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <span
          style={{ 
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '130px',
            textAlign: 'center'
          }}
        >
          {row.owner_unit || '-'}
        </span>
      ),
      sortable: true
    },
    {
      field: 'petition_subject',
      headerName: '陳情主旨',
      minWidth: 300,
      align: 'left',
      headerAlign: 'center',
      sortable: false,
      flex: 1,
    },
    {
      field: 'satisfaction',
      headerName: '滿意度',
      width: 110,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => (
        <span>
          {row.satisfaction || '-'}
        </span>
      ),
      renderHeader: (params) => (
        <CustomHeader field="satisfaction" sortModel={params.sortModel} onSortModelChange={params.onSortModelChange} />
      ),
      sortable: true,
      sortComparator: satisfactionSortComparator
    },
    {
      field: 'detail',
      headerName: '檢視',
      width: 70,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Link 
          to={`/dashboard/case-analysis/caseId/${row.case_id}`}
          style={{
            lineHeight: 'normal'
          }}
        >
          <CaseDetailIcon fill='#737373' />
        </Link>
      ),
    },
  ];

  const CustomTab = styled(Tab)(() => ({
    '&.MuiButtonBase-root': {
      padding: 0,
      color: '#0A0A0A',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 600,
      minWidth: 'auto',
      minHeight: 'auto',
      borderRadius: '99px',
      marginRight: '4px',
      padding: '4px 20px',
      transition: 'all .3s',
      '&:hover': {
        backgroundColor: '#fff',
        boxShadow: '10px 0 20px 0 rgba(0, 0, 0, 0.05)'
      }
    },
    '&.Mui-selected': {
      backgroundColor: '#fff',
      boxShadow: '10px 0 20px 0 rgba(0, 0, 0, 0.05)'
    }
  }))

  const CustomDataGrid = styled(DataGrid)(({theme}) => ({
    '.MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '.MuiDataGrid-withBorderColor': {
      borderColor: 'transparent'
    },
    '.MuiDataGrid-columnHeaders': {
      maxHeight: 'unset !important',
      width: '100%',
      backgroundColor: theme.palette.primary.light,
      borderRadius: '8px 8px 0 0'
    },
    '.MuiDataGrid-columnHeader': {
      backgroundColor: theme.palette.primary.light,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      borderRight: `2px solid ${theme.palette.bg.white}`,
      maxHeight: 'unset',
      padding: '0 20px',
      '&:last-child': {
        borderRight: 'none'
      }
    },
    '.MuiDataGrid-columnHeaderTitle': {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 600
    },
    '.MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '.MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '.MuiDataGrid-cell': {
      padding: '0 20px',
      borderRight: `2px solid ${theme.palette.bg.white}`,
      '&:last-child': {
        borderRight: 'none'
      }
    },
    '.MuiDataGrid-footerContainer': {
      justifyContent: 'center'
    },
    '.odd-row': {
      backgroundColor: theme.palette.bg.grey,
      border: 'none'
    },
    '.even-row': {
      backgroundColor: theme.palette.bg.light
    },
    border: 'none',
    height: '630px',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600
  }))

  const DataGridSkeleton = () => {
    const skeletonRows = Array.from({ length: 11 }).map((_, index) => (
      <div 
        key={index} 
      >
        <Skeleton 
          variant="rectangular" 
          sx={{
            width: '100%',
            height: '40px',
            marginTop: '10px',
            borderRadius: '10px'
          }}
          animation={index % 2 === 0 ? 'pulse' : 'wave'}
          animationspeed={index % 2 === 0 ? 'fast' : 'slow'}
        />
      </div>
    ));
  
    return (
      <div>
        {skeletonRows}
      </div>
    );
  };

  const CustomHeader = ({ field, sortModel, onSortModelChange, pagination, setPagination, setSearchAfterFirstParam, setSearchAfterParam, setChunkIndex }) => {
    let sortIcon;
    const handleSortChange = () => {
      const currentSort = sortModel.find((item) => item.field === field);
      const newSort = currentSort?.sort === 'asc' ? 'desc' : 'asc';

      if (pagination.page !== 0) {
        setPagination({ ...pagination, page: 0 })
        setSearchAfterFirstParam(null)
        setSearchAfterParam(null)
        setChunkIndex(1)
        onSortModelChange([{ field, sort: newSort }]);
      } else {
        setPagination({ ...pagination, page: 0 })
        onSortModelChange([{ field, sort: newSort }]);
      }
    };
    
    if (sortModel.find((item) => item.field === field)?.sort === 'asc') {
      sortIcon = <SortUpIcon />
    } else if (sortModel.find((item) => item.field === field)?.sort === 'desc') {
      sortIcon = <SortDownIcon />
    } else {
      sortIcon = <SortDefaultIcon />
    }

    return (
      <div>
        <IconButton 
          onClick={handleSortChange} 
          size="small" 
          sx={{ 
            display: 'flex', 
            flexFlow: 'column wrap',
            padding: '0'
          }}
        >
          {sortIcon}
        </IconButton>
      </div>
    );
  };

  const useHandleQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  
  function CaseAnalysis() {
    const { formattedMinDate, formattedMaxDate } = UseDateRangeStore()
    const [value, setValue] = useState('trend');
    const [ownerUnitData, setOwnerUnitData] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [previousPeriodData, setPreviousPeriodData] = useState({});
    const [currentPeriodData, setCurrentPeriodData] = useState({});
    const [previousPeriodTotalCount, setPreviousPeriodTotalCount] = useState(0);
    const [previousFormattedMinDate, setPreviousFormattedMinDate] = useState(formattedMinDate);
    const [previousFormattedMaxDate, setPreviousFormattedMaxDate] = useState(formattedMaxDate);
    const [searchAfterFirstParam, setSearchAfterFirstParam] = useState(null);
    const [searchAfterParam, setSearchAfterParam] = useState(null);

    const [pagination, setPagination] = useState({
      pageSize: 100,
      page: 0,
    });
    const [sortModel, setSortModel] = useState([{
      field: 'record_time',
      sort: 'desc'
    }]);

    const [ownerUnitAnchorEl, setOwnerUnitAnchorEl] = useState(null);
    const [subOwnerUnitMenuAnchorEl, setSubOwnerUnitMenuAnchorEl] = useState(null);
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
    const [subCategoryMenuAnchorEl, setSubCategoryMenuAnchorEl] = useState(null);
    const [searchMenuAnchorEl, setSearchMenuAnchorEl] = useState(null);

    const [currentOwnerUnit, setCurrentOwnerUnit] = useState('');
    const [currentCategory, setCurrentCategory] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagAreaExpand, setTagAreaExpand] = useState(true);
    const [disabledSearch, setDisabledSearch] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [ownerUnitSearchValue, setOwnerUnitSearchValue] = useState('');
    const [filteredOwnerUnit, setFilteredOwnerUnit] = useState([]);
    const [triggerFromOtherPage, setTriggerFromOtherPage] = useState(true);
    const [isReportExcelLoading, setIsReportExcelLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    const [chunkIndex, setChunkIndex] = useState(1);

    const ownerUnitMenuOpen = Boolean(ownerUnitAnchorEl);
    const subOwnerUnitMenuOpen = Boolean(subOwnerUnitMenuAnchorEl);
    const categoryMenuOpen = Boolean(categoryAnchorEl);
    const subCategoryMenuOpen = Boolean(subCategoryMenuAnchorEl);
    const searchMenuOpen = Boolean(searchMenuAnchorEl);

    const ListItem = styled('li')(({ theme }) => ({
      margin: theme.spacing(0.5),
      display: 'inline-block'
    }));

    const query = useHandleQuery();
    const triggerSearch = query.get('triggerSearch') === 'true';

    const { setTableData, tableData } = UseTableDataStore()
    const { subOwnerUnitsSelected, ownerUnitSelected, updateOwnerUnitSelected, updateSubOwnerUnitSelected, deleteOwnerUnitSelected, clearOwnerUnitSelected } = UseOwnerUnitStore()
    const { subCategoriesSelected, updateMainCategorySelected, updateSubCategorySelected, deleteSubCategorySelected, clearSubCategorySelected } = UseSubCategoryStore()
    const { caseData, isLoading, setCaseData, setIsLoading } = UseCaseDataStore()
    const { searchSelected, updateSearchSelected, deleteSearchSelected, clearSearchSelected } = UseSearchStore()
    const { satisfaction, updateSatisfaction } = UseSatisfactionStore()

    // 一進入案件分析取得第一個 chunk 資料
    const { 
      data: caseFirstChunkData, 
      isLoading: isLoadingCaseFirstChunkData, 
      isError: isErrorCaseFirstChunkData, 
      error: caseFirstChunkDataError,
      refetch: refetchCaseFirstChunkData
    } = useQuery({
      queryFn: () => getCasesFirst(formattedMinDate, formattedMaxDate, subOwnerUnitsSelected, subCategoriesSelected, searchSelected, satisfaction, 1, null, sortModel[0].field, sortModel[0].sort),
      queryKey: ['casesFirst', formattedMinDate, formattedMaxDate],
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 60000,
      cacheTime: 300000,
      retry: false
    })

    // 取得其他 chunk 資料
    const { 
      data: caseChunkData, 
      isFetching: isFetchingCaseChunkData, 
      isError: isErrorCaseChunkData, 
      refetch: refetchCaseChunkData 
    } = useQuery({
      queryFn: () => getCases(formattedMinDate, formattedMaxDate, subOwnerUnitsSelected, subCategoriesSelected, searchSelected, satisfaction, chunkIndex, searchAfterParam, sortModel[0].field, sortModel[0].sort),
      queryKey: ['cases', formattedMinDate, formattedMaxDate],
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      retry: false
    })

    // call 當期文字雲 API
    const { 
      data: wordCloudData, 
      isLoading: isLoadingWordCloudData, 
      isFetching: isFetchingWordCloudData, 
      isError: isErrorWordCloudData,
      error: wordCloudDataError,
      refetch: refetchWordCloudData
    } = useQuery({
      queryFn: () => getWordCloud(formattedMinDate, formattedMaxDate, subOwnerUnitsSelected, subCategoriesSelected, searchSelected, satisfaction),
      queryKey: ['wordCloudData', formattedMinDate, formattedMaxDate],
      refetchOnWindowFocus: false,
      staleTime: 60000,
      cacheTime: 300000,
      retry: false
    })

    // call 前期文字雲 API
    const { 
      data: previousWordCloudData, 
      isLoading: isLoadingPreviousWordCloudData, 
      isFetching: isFetchingPreviousWordCloudData, 
      isError: isErrorPreviousWordCloudData, 
      error: previousWordCloudDataError,
      refetch: refetchPreviousWordCloudData 
    } = useQuery({
      queryFn: () => getPreviousWordCloud(previousFormattedMinDate, previousFormattedMaxDate, subOwnerUnitsSelected, subCategoriesSelected, searchSelected, satisfaction),
      queryKey: ['previousWordCloudData', previousFormattedMinDate, previousFormattedMaxDate],
      refetchOnWindowFocus: false,
      staleTime: 60000,
      cacheTime: 300000,
      retry: false
    })

    // call 匯出案件分析 Excel API
    const { 
      data: reportCasesExcelData, 
      refetch: refetchReportCasesExcelData 
    } = useQuery({
      queryFn: () => getReportCasesExcelData(formattedMinDate, formattedMaxDate, subOwnerUnitsSelected, subCategoriesSelected, searchSelected),
      enabled: false,
      queryKey: ['reportCasesExcelData'],
      refetchOnWindowFocus: false,
    })

    useEffect(() => {
      if (isErrorCaseFirstChunkData || isErrorWordCloudData || isErrorPreviousWordCloudData) {
        setErrorMessage((caseFirstChunkDataError || wordCloudDataError || previousWordCloudDataError).message);
      } else {
        setErrorMessage(null)
      }
    }, [isErrorCaseFirstChunkData, isErrorWordCloudData, isErrorPreviousWordCloudData, caseFirstChunkDataError, wordCloudDataError, previousWordCloudDataError])

    useEffect(() => {
      if (errorMessage !== null) {
        setTimeout(() => {
          setShowAlert(false);
          setErrorMessage(null);
        }, 3000);
  
        setShowAlert(true);
      }
    }, [errorMessage]);

    useEffect(() => {
      setSearchAfterFirstParam(null)
      setSearchAfterParam(null)
      setChunkIndex(1)
      clearFetchedChunks()  // 清除暫存的 chunk 資料
    }, [formattedMinDate, formattedMaxDate]);


    useEffect(() => {
      if (chunkIndex === 1) {
        setSearchAfterParam(null)
        clearFetchedChunks()
      }
    }, [chunkIndex])

    useEffect(() => {
      if (chunkIndex !== 1) {
        refetchCaseChunkData()
      }
    }, [chunkIndex])

    useEffect(() => {
      if (chunkIndex !== 1) {
        refetchCaseChunkData()
      }
    }, [sortModel])

    useEffect(() => {
      refetchCaseFirstChunkData()
    }, [sortModel])

    useEffect(() => {
      if (disabledSearch) {
        refetchCaseFirstChunkData()
        refetchWordCloudData()
        refetchPreviousWordCloudData()
      }
    }, [disabledSearch])

    useEffect(() => {
      setChunkIndex(Math.ceil((pagination.page + 1) / 10))
    }, [pagination.page])

    // 獲取 first chunk 資料的處理
    useEffect(() => {
      if (caseFirstChunkData) {
        setCaseData(caseFirstChunkData)
        // console.log('caseFirstChunkData', caseFirstChunkData)
        // console.log('searchAfterFirstParam', caseFirstChunkData.search_after)
        setSearchAfterFirstParam(caseFirstChunkData.search_after)
        setSearchAfterParam(caseFirstChunkData.search_after)
      }
    }, [caseData, caseFirstChunkData, formattedMinDate, formattedMaxDate, sortModel])

    // 每當有 first chunk 回傳的 search_after 參數，就存起來給之後取得其他 chunk 時使用
    useEffect(() => {
      if (searchAfterFirstParam && chunkIndex === 1) {
        setSearchAfterParam(searchAfterFirstParam)
      }
    }, [searchAfterFirstParam, chunkIndex, sortModel])

    // 獲取其他 chunk 資料的處理
    useEffect(() => {
      if (caseChunkData && chunkIndex !== 1) {
        // console.log('searchAfterParam', caseChunkData.search_after)
        setSearchAfterParam(caseChunkData.search_after)
        const newTableData = caseChunkData.responseData
        setTableData([...caseData?.responseData].concat(newTableData))
      }
    }, [caseData, caseChunkData, formattedMinDate, formattedMaxDate, sortModel, chunkIndex])

    const handleSortModelChange = (newSortModel) => {
      // console.log('newSortModel', newSortModel);
      setSortModel(newSortModel);
      clearFetchedChunks()
    };

    // 點擊顯示承辦機關 Menu
    const showOwnerUnitMenu = (event) => {
      setOwnerUnitAnchorEl(event.currentTarget);
    };

    // 點擊開啟主案類 Menu
    const showCategoryMenu = (event) => {
      setCategoryAnchorEl(event.currentTarget);
    };
    
    // 點擊顯示次案類 Menu
    const showSubCategoryMenu = (event, category) => {
      setSubCategoryMenuAnchorEl(event.currentTarget);
      setCurrentCategory(category);
    };

    // 點擊顯示二級機關 Menu
    const showSubOwnerUnitMenu = (event, ownerUnit) => {
      setSubOwnerUnitMenuAnchorEl(event.currentTarget);
      setCurrentOwnerUnit(ownerUnit);
    };

    // 點擊顯示搜尋關鍵字 Menu
    const showSearchMenu = (event) => {
      setSearchMenuAnchorEl(event.currentTarget);
    };

    // 關閉承辦機關選單
    const handleOwnerUnitMenuClose = () => {
      setOwnerUnitAnchorEl(null);
      setSubOwnerUnitMenuAnchorEl(null);
      setCurrentOwnerUnit('')
      setOwnerUnitSearchValue('')
      caseData && setOwnerUnitData(Object.keys(caseData.owner_unit.owner_unit_hierarchy))
    };
    
    // 關閉案件分類選單
    const handleCategoryMenuClose = () => {
      setCategoryAnchorEl(null);
      setSubCategoryMenuAnchorEl(null);
      setCurrentCategory('')
    };

    // 關閉搜尋關鍵字選單
    const handleSearchMenuClose = () => {
      setSearchMenuAnchorEl(null);
    };

    // 處理一級承辦機關的選取狀態
    const handleOwnerUnitChange = (ownerUnit, isChecked) => {
      updateOwnerUnitSelected(isChecked, ownerUnit, caseData)
    };

    // 處理二級承辦機關的選取狀態
    const handleSubOwnerUnitChange = (ownerUnit, subOwnerUnit, isChecked) => {
      updateSubOwnerUnitSelected(ownerUnit, subOwnerUnit, isChecked)
    };

    // 處理主案類的選取狀態
    const handleCategoryChange = (category, isChecked) => {
      updateMainCategorySelected(isChecked, category, caseData)
    };

    // 處理次案類的選取狀態
    const handleSubCategoryChange = (category, subCategory, isChecked) => {
      updateSubCategorySelected(category, subCategory, isChecked)
    };


    // 處理搜尋關鍵字邏輯和內容的狀態
    const handleSearchChange = (value) => {
      if (value !== '') {
        updateSearchSelected(value);
        setSearchValue('');
      }
    };

    // 處理搜尋關鍵字內容的狀態
    const handleSearchValueChange = (e) => {
      setSearchValue(e.target.value)
    }

    // 處理搜尋機關的狀態
    const handleOwnerUnitValueChange = (e) => {
      setOwnerUnitSearchValue(e.target.value)
      debouncedFilteredOwnerUnitResult(e.target.value)
    }

    const handleFilteredOwnerUnitResult = (searchValue) => {
      if (caseData) {
        const hierarchy = caseData.owner_unit.owner_unit_hierarchy;
        if (searchValue !== '') {
          const filteredOwnerUnits = {};
          Object.keys(hierarchy).forEach(ownerUnit => {
            const subOwnerUnits = hierarchy[ownerUnit]?.filter(subOwnerUnit => subOwnerUnit.includes(searchValue));
            if (subOwnerUnits.length > 0) {
              filteredOwnerUnits[ownerUnit] = subOwnerUnits;
            }
          });
          setFilteredOwnerUnit(filteredOwnerUnits);
          setOwnerUnitData(Object.keys(filteredOwnerUnits));
        } else {
          // 清空搜尋時恢復原本機關資料
          setFilteredOwnerUnit(hierarchy);
          setOwnerUnitData(Object.keys(hierarchy));
        }
      }
    }

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    const debouncedFilteredOwnerUnitResult = useCallback(debounce(handleFilteredOwnerUnitResult, 500), [caseData])

    // 主案類的 checkbox 狀態判斷
    const isCategoryChecked = (category) => {
      const subCategories = caseData?.categories[category] || [];
      const selectedSubCategories = subCategoriesSelected[category] || [];
      if (selectedSubCategories.length === subCategories.length) return 'all';
      if (selectedSubCategories.length > 0) return 'partial';
      return 'none';
    };

    // 一級機關的 checkbox 狀態判斷
    const isOwnerUnitChecked = (ownerUnit) => {
      const subOwnerUnits = caseData?.owner_unit?.owner_unit_hierarchy[ownerUnit] || [];
      const selectedSubOwnerUnits = subOwnerUnitsSelected[ownerUnit] || [];
      if (selectedSubOwnerUnits.length === subOwnerUnits.length) return 'all';
      if (selectedSubOwnerUnits.length > 0) return 'partial';
      return 'none';
    };

    // 刪除 tags
    const handleDeleteTag = (chipToDelete) => () => {
      setSelectedTags((prev) => prev.filter((item) => item.tag !== chipToDelete));
      deleteOwnerUnitSelected(chipToDelete);
      deleteSubCategorySelected(chipToDelete)
      clearSearchSelected()
    };

    // 清空已選的 tags
    const handleResetTags = () => {
      setSelectedTags([])
      clearOwnerUnitSelected()
      clearSubCategorySelected()
      clearSearchSelected()
      updateSatisfaction([])
      setDisabledSearch(true)
    }

    // 切換日期，重新獲取資料
    useEffect(() => {
      // console.log('formattedMinDate', formattedMinDate);
      // console.log('formattedMaxDate', formattedMaxDate);

      if (caseData && caseData.responseData) {
        const previousPeriodDates = Object.keys(caseData.previous_period)
        // setTableData(caseData.responseData);
        setOwnerUnitData(Object.keys(caseData.owner_unit.owner_unit_hierarchy));
        // setCategoriesData(Object.keys(caseData.categories));
        handleFilterCategoriesData();
        setPreviousPeriodData(caseData.previous_period);
        setCurrentPeriodData(caseData.current_period);
        setPreviousPeriodTotalCount(caseData.previous_period_total_count)
        setPreviousFormattedMinDate(previousPeriodDates[0])
        setPreviousFormattedMaxDate(previousPeriodDates[previousPeriodDates.length - 1])
        // console.log('承辦機關 data.owner_unit keys', Object.keys(data?.owner_unit));
        // console.log('選擇案件 主案件', categoriesSelected);
        // console.log('選擇機關', ownerUnitSelected);
        // console.log('選擇案件 子案件', subCategoriesSelected);
        // console.log('選擇案件 目前主案件', currentCategory);
        // console.log('選擇Tag', selectedTags);
        // console.log('頁面分析頁 caseData', caseData)
      }    
    }, [caseData, formattedMinDate, formattedMaxDate, subOwnerUnitsSelected, subCategoriesSelected, satisfaction]);

    useEffect(() => {
      setTableData(caseData?.responseData)
      setTriggerFromOtherPage(true)
    }, [caseData])

    useEffect(() => {
      setPagination({ ...pagination, page: 0 })
    }, [formattedMinDate, formattedMaxDate])

    useEffect(() => {
      setDisabledSearch(false)
    }, [selectedTags])

    // 案件分析 Excel 匯出
    useEffect(() => {
      if (reportCasesExcelData && !triggerFromOtherPage) {
        try {
          const url = window.URL.createObjectURL(reportCasesExcelData);
          const a = document.createElement('a');
          a.href = url;
          a.download = `report-${formattedMinDate}-${formattedMaxDate}.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          setIsReportExcelLoading(false);
        } catch (err) {
          console.error(err);
        }
      }
    }, [reportCasesExcelData, triggerFromOtherPage]);

    // 將所有已選取的各類 tags 合併
    useEffect(() => {
      // 因多個二級機關為相同名稱，須另外加上其一級機關名稱一同顯示
      const selectedSubOwnerUnitTags = Object.entries(subOwnerUnitsSelected).map(([key, value]) => {
        return value.map(item => {
          if (item === '秘書室') {
            return item.replace('秘書室', `${key}/秘書室`)
          } else if (item === '政風室') {
            return item.replace('政風室', `${key}/政風室`)
          } else if (item === '人事室') {
            return item.replace('人事室', `${key}/人事室`)
          } else if (item === '綜合規劃科') {
            return item.replace('綜合規劃科', `${key}/綜合規劃科`)
          } else if (item === '綜合企劃科') {
            return item.replace('綜合企劃科', `${key}/綜合企劃科`)
          }
          return item
        })
      }).flat()
      

      const transformOwnerUnitTags = [...selectedSubOwnerUnitTags].map(item => {
        return { type: 'ownerUnit', tag: item}
      })

      // 因多個次案類皆有'其他'，須另外加上其主案類名稱一同顯示
      const selectedSubCategoryTags = Object.entries(subCategoriesSelected).map(([key, value]) => {
        return value.map(item => {
          if (item === '其他') {
            return item.replace('其他', `${key}/其他`)
          }
          return item
        })
      }).flat()

      const transformSubCategoryTags = [...selectedSubCategoryTags].map(item => {
        return { type: 'category', tag: item }
      })

      const transformSearchTags = { type: 'searchInclude', tag: searchSelected }

      // console.log('transformOwnerUnitTags', transformOwnerUnitTags)
      // console.log('transformSubCategoryTags', transformSubCategoryTags)
      // console.log('result tags', [...transformOwnerUnitTags, ...transformSubCategoryTags, ...transformSearchTags])

      if (searchSelected) {
        setSelectedTags([...transformOwnerUnitTags, ...transformSubCategoryTags, transformSearchTags]);
      } else {
        setSelectedTags([...transformOwnerUnitTags, ...transformSubCategoryTags]);
      }
    }, [subOwnerUnitsSelected, subCategoriesSelected, searchSelected])

    // 當選取承辦機關時，只篩選出其相應的主案類
    const handleFilterCategoriesData = () => {
      const categories = new Set();
      Object.entries(subOwnerUnitsSelected)?.forEach(([ownerUnit, subOwnerUnits]) => {
        subOwnerUnits?.forEach((subOwnerUnit => {
          caseData?.owner_unit?.owner_unit_category[ownerUnit]?.sub_unit[subOwnerUnit].main_category.forEach(category => {
            if (Object.keys(caseData?.categories)?.find(item => item === category)) {
              categories.add(category)
            }
          })
        }))
      });
      // console.log('Array.from(categories)', Array.from(categories));

      // 若沒選取承辦機關，則渲染所有的案件類別
      if (Object.keys(subOwnerUnitsSelected)?.length === 0) {
        setCategoriesData(Object.keys(caseData?.categories))
      } else {
        setCategoriesData(Array.from(categories));
      }
    }
   
    // 點選切換頁數
    const handlePageChange = (event, newPage) => {
      const pageIndex = Number(newPage) - 1;
      if (pageIndex >= 0 && pageIndex <= Math.ceil(caseData?.totalCount / pagination.pageSize)) {
        setPagination({ ...pagination, page: pageIndex });
      }
    };

    // 跳轉到指定頁數
    const handleJumpToPage = (e) => {
      const pageNumber = parseInt(e.target.value)
      if (!isNaN(pageNumber) && pageNumber >= 1) {
        const pageIndex = pageNumber - 1
        if (pageIndex >= 0 && pageIndex < Math.ceil(caseData?.totalCount / pagination.pageSize)) {
          setPagination({ ...pagination, page: pageIndex })
        }
      }
    };

    // 將取得的表格資料過濾重複項並加上 id
    const transformData = (data) => {
      const caseIds = new Set()

      return data.filter(row => {
        if (caseIds.has(row.case_id)) {
          return false
        } else {
          caseIds.add(row.case_id)
          return true
        }
      }).map((row, index) => {
        return {...row, id: index + 1};
      });
    }

    // 前後分析柱狀圖表資料轉換
    const transformedColumnChartCategories = (previousPeriod, currentPeriod) => {
      const previousData = Object.entries(previousPeriod);
      const currentData = Object.entries(currentPeriod);

      const categoriesCombine = previousData.map(
        ([prevDate], index) => `${transformChartDate(prevDate)} - ${transformChartDate(currentData[index][0])}`
      );

      const previousSeriesData = previousData.map(([date, count]) => ({ name: date, y: count }));
      const currentSeriesData = currentData.map(([date, count]) => ({ name: date, y: count }));

      return { categoriesCombine, previousSeriesData, currentSeriesData }
    }
    
    // 切換分析圖 Tab
    const handleTabChange = (e, newValue) => {
      setValue(newValue);
    }

    // 篩選器查詢
    const handleSearch = () => {
      if (searchValue === '') {
        refetchCaseFirstChunkData()
        refetchWordCloudData()
        refetchPreviousWordCloudData()
      }
    }

    useEffect(() => {
      if (triggerSearch) {
        handleSearch();
      } else {
        refetchCaseFirstChunkData();
      }
    }, [triggerSearch]);

    const getRowClassName = (params) => {
      return params.id % 2 === 0 ? 'even-row' : 'odd-row';
    };

    return (
      <>
        <Paper 
          sx={{
            m: 2,
            borderRadius: '16px',
            height: 'auto',
            boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.05)'
          }}
        >
          <Stack
            sx={{
              pt: '20px',
              pb: '20px',
              borderBottom: (theme) => `1px solid ${theme.palette.neutral.light}`
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  pl: '16px',
                  mr: '16px',
                  borderLeft: '4px solid #52697A',
                  fontWeight: 400
                }}
              >
                案件分析
              </Typography>

              {/* 篩選承辦機關 */}
              <Stack
                // sx={{
                //   mr: '8px'
                // }}
                sx={{
                  width: '270px',
                  padding: '0',
                  display: 'flex',
                  flexFlow: 'row wrap',
                  alignItems: 'center',
                  border: '1px solid #E5E5E5',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  ml: '8px',
                  mr: '8px',
                  '&:hover': {
                    background: '#FAFAFA'
                  },
                  '&:focus': {
                    background: '#FFFFFF',
                    border: '1px solid #000'
                  }
                }}
              >
                <Button 
                  onClick={showOwnerUnitMenu}
                  disabled={isLoading || isFetchingCaseChunkData}
                  sx={{
                    width: '270px',
                    background: '#FFFFFF',
                    border: '1px solid #E5E5E5',
                    color: '#737373',
                    padding: '12px 20px',
                    fontWeight: 400,
                    borderRadius: '8px',
                    justifyContent: 'space-between',
                    '&:hover': {
                      background: '#FAFAFA'
                    },
                    '&:focus': {
                      background: '#FFFFFF',
                      border: '1px solid #52697A'
                    }
                  }}
                >
                  <span>選擇承辦機關</span>
                  <SelectDownIcon fill="#737373" />
                </Button>
                <Menu
                  anchorEl={ownerUnitAnchorEl}
                  open={ownerUnitMenuOpen}
                  onClose={handleOwnerUnitMenuClose}
                  sx={{
                    '& .MuiPaper-root': {
                      marginTop: '5px',
                      position: 'absolute',
                      left: '0px',
                      top: '0px',
                      borderRadius: '8px',
                      width: '660px',
                      height: '545px',
                      border: '1px solid #52697A',
                      '& .MuiList-root': {
                        display: 'flex',
                        flexFlow: 'row wrap',
                        padding: 0
                      },
                      overflow: 'hidden'
                    }
                  }}
                >
                  <Stack
                    sx={{
                      width: '100%',
                      p: '10px',
                    }}
                  >
                    <TextField 
                      variant="standard" 
                      value={ownerUnitSearchValue}
                      hiddenLabel
                      placeholder='搜尋承辦機關...'
                      onChange={handleOwnerUnitValueChange}
                      disabled={isLoading || isFetchingCaseChunkData}
                      sx={{ 
                        width: '100%',
                        height: '40px',
                        p: '5px 10px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        '& .MuiInputBase-root': {
                          height: '100%',
                          backgroundColor: 'transparent',
                          fontSize: '14px',
                          '&::before': {
                            borderBottom: 'none',
                          },
                          '&:hover': {
                            backgroundColor: 'transparent',
                            '&::before': {
                              borderBottom: 'none',
                            },
                          },
                          '&.Mui-disabled': {
                            '&::before': {
                              borderBottom: 'none',
                            },
                          }
                        },
                        '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
                          borderBottom: 'none',
                          backgroundColor: 'transparent',
                          'input:-internal-autofill-selected': {
                            backgroundColor: 'transparent !important'
                          }
                        },
                        '& .MuiInput-root::after': {
                          borderBottom: 'none'
                        },
                        '& .MuiInput-root:-webkit-autofill': {
                          backgroundColor: 'transparent'
                        }
                      }} 
                    />
                  </Stack>
                  <Stack
                    sx={{
                      width: '330px', 
                      height: '480px',
                      padding: '8px 0',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      borderRight: '1px solid #ccc',
                      boxShadow: '10px 0 20px 0 rgba(0, 0, 0, .05)',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                        borderRadius: '10px',
                        marginTop: '1px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#D4D4D4',
                        borderRadius: '10px',
                      },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        p: '4px 0 4px 16px',
                        mr: '16px',
                        borderLeft: '4px solid #52697A',
                        fontWeight: 400,
                      }}
                    >
                      一級機關
                    </Typography>
                    {(ownerUnitSearchValue === '' ? ownerUnitData : Object.keys(filteredOwnerUnit))?.map((ownerUnit) => {
                      const checkedStatus = isOwnerUnitChecked(ownerUnit)
                      return (
                        <MenuItem 
                          key={ownerUnit}
                          sx={{
                            padding: '3px 10px',
                            margin: '2px 0',
                            '& .MuiTypography-root': {
                              fontWeight: 400,
                              fontSize: '14px',
                              lineHeight: '21px',
                              letterSpacing: '1px',
                              color: (theme) => theme.palette.neutral.silver
                            }
                          }}
                          onMouseOver={(event) => showSubOwnerUnitMenu(event, ownerUnit)}
                        >
                          <Checkbox
                            checked={checkedStatus === 'all'}
                            indeterminate={checkedStatus === 'partial'}
                            icon={<CheckDefaultIcon />}
                            checkedIcon={<CheckSelectedIcon />}
                            indeterminateIcon={<CheckPartialIcon />}
                            onChange={(event) => {
                              handleOwnerUnitChange(ownerUnit, event.target.checked);
                            }}
                          />
                          <ListItemText 
                            primary={ownerUnit} 
                            sx={{
                              whiteSpace: 'pre-line',
                              '& .MuiTypography-root': {
                                fontWeight: checkedStatus === 'all' || checkedStatus === 'partial' ? 'bold' : 'normal'
                              }
                            }}
                          />
                          <IconButton
                            onClick={(event) => showSubOwnerUnitMenu(event, ownerUnit)}
                          >
                            <SubCategoryOpenIcon fill={`${checkedStatus === 'all' || checkedStatus === 'partial' ? '#52697A' : '#D4D4D4'}`} />
                          </IconButton>
                        </MenuItem>
                      )
                    })}
                  </Stack>

                  <Stack
                    sx={{
                      width: 'calc(100% - 330px)',
                      height: '480px',
                      padding: '8px 0',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                        borderRadius: '10px',
                        marginTop: '1px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#D4D4D4',
                        borderRadius: '10px',
                      },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        p: '4px 0 4px 16px',
                        mr: '16px',
                        borderLeft: '4px solid #52697A',
                        fontWeight: 400
                      }}
                    >
                      二級機關
                    </Typography>
                    {subOwnerUnitMenuOpen && currentOwnerUnit && (
                      <>
                        {(ownerUnitSearchValue === '' ? caseData?.owner_unit?.owner_unit_hierarchy : filteredOwnerUnit)[currentOwnerUnit]?.map((subOwnerUnit) => (
                          <MenuItem 
                            key={subOwnerUnit} 
                            value={subOwnerUnit}
                            sx={{
                              padding: '3px 10px',
                              margin: '2px 0',
                              '& .MuiTypography-root': {
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '21px',
                                letterSpacing: '1px',
                                color: (theme) => theme.palette.neutral.silver
                              }
                            }}
                          >
                            <Checkbox
                              checked={subOwnerUnitsSelected[currentOwnerUnit]?.includes(subOwnerUnit) || false}
                              icon={<CheckDefaultIcon />}
                              checkedIcon={<CheckSelectedIcon />}
                              onChange={(event) => {
                                handleSubOwnerUnitChange(currentOwnerUnit, subOwnerUnit, event.target.checked)
                              }}
                            />
                            <ListItemText 
                              primary={subOwnerUnit} 
                              sx={{
                                whiteSpace: 'pre-line'
                              }}
                            />
                          </MenuItem>
                        ))}
                      </>
                      )
                    }
                  </Stack>
                </Menu>
              </Stack>

              {/* 篩選案件分類 */}
              <Stack>
                <Button 
                  onClick={showCategoryMenu}
                  disabled={isLoading || isFetchingCaseChunkData}
                  sx={{
                    width: '270px',
                    background: '#FFFFFF',
                    border: '1px solid #E5E5E5',
                    color: '#737373',
                    padding: '12px 20px',
                    fontWeight: 400,
                    borderRadius: '8px',
                    justifyContent: 'space-between',
                    '&:hover': {
                      background: '#FAFAFA'
                    },
                    '&:focus': {
                      background: '#FFFFFF',
                      border: '1px solid #52697A'
                    }
                  }}
                >
                  <span>選擇案件分類</span>
                  <SelectDownIcon fill="#737373" />
                </Button>
                <Menu
                  anchorEl={categoryAnchorEl}
                  open={categoryMenuOpen}
                  onClose={handleCategoryMenuClose}
                  sx={{
                    '& .MuiPaper-root': {
                      marginTop: '5px',
                      position: 'absolute',
                      left: '0px',
                      top: '0px',
                      borderRadius: '8px',
                      width: '660px',
                      height: '480px',
                      border: '1px solid #52697A',
                      '& .MuiList-root': {
                        display: 'flex',
                        flexFlow: 'row wrap',
                        padding: 0
                      },
                      overflow: 'hidden'
                    }
                  }}
                >
                  <Stack
                    sx={{
                      width: '330px', 
                      height: '480px',
                      padding: '8px 0',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      borderRight: '1px solid #ccc',
                      boxShadow: '10px 0 20px 0 rgba(0, 0, 0, .05)',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                        borderRadius: '10px',
                        marginTop: '1px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#D4D4D4',
                        borderRadius: '10px',
                      },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        p: '4px 0 4px 16px',
                        mr: '16px',
                        borderLeft: '4px solid #52697A',
                        fontWeight: 400
                      }}
                    >
                      主案類
                    </Typography>
                    {categoriesData.map((category) => {
                      const checkedStatus = isCategoryChecked(category)
                      return (
                        <MenuItem 
                          key={category}
                          sx={{
                            padding: '3px 10px',
                            margin: '2px 0',
                            '& .MuiTypography-root': {
                              fontWeight: 400,
                              fontSize: '14px',
                              lineHeight: '21px',
                              letterSpacing: '1px',
                              color: (theme) => theme.palette.neutral.silver
                            }
                          }}
                          onMouseOver={(event) => showSubCategoryMenu(event, category)}
                        >
                          <Checkbox
                            checked={checkedStatus === 'all'}
                            indeterminate={checkedStatus === 'partial'}
                            icon={<CheckDefaultIcon />}
                            checkedIcon={<CheckSelectedIcon />}
                            indeterminateIcon={<CheckPartialIcon />}
                            onChange={(event) => {
                              handleCategoryChange(category, event.target.checked);
                            }}
                          />
                          <ListItemText 
                            primary={category} 
                            sx={{
                              whiteSpace: 'pre-line',
                              '& .MuiTypography-root': {
                                fontWeight: checkedStatus === 'all' || checkedStatus === 'partial' ? 'bold' : 'normal'
                              }
                            }}
                          />
                          <IconButton
                            onClick={(event) => showSubCategoryMenu(event, category)}
                          >
                            <SubCategoryOpenIcon fill={`${checkedStatus === 'all' || checkedStatus === 'partial' ? '#52697A' : '#D4D4D4'}`} />
                          </IconButton>
                        </MenuItem>
                      )
                    })}
                  </Stack>

                  <Stack
                    sx={{
                      width: 'calc(100% - 330px)',
                      height: '480px',
                      padding: '8px 0',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                        borderRadius: '10px',
                        marginTop: '1px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#D4D4D4',
                        borderRadius: '10px',
                      },
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        p: '4px 0 4px 16px',
                        mr: '16px',
                        borderLeft: '4px solid #52697A',
                        fontWeight: 400
                      }}
                    >
                      次案類
                    </Typography>
                    {subCategoryMenuOpen && currentCategory && (
                      <>
                        {caseData?.categories[currentCategory].map((subCategory) => (
                          <MenuItem 
                            key={subCategory} 
                            value={subCategory}
                            sx={{
                              padding: '3px 10px',
                              margin: '2px 0',
                              '& .MuiTypography-root': {
                                fontWeight: 400,
                                fontSize: '14px',
                                lineHeight: '21px',
                                letterSpacing: '1px',
                                color: (theme) => theme.palette.neutral.silver
                              }
                            }}
                          >
                            <Checkbox
                              checked={subCategoriesSelected[currentCategory]?.includes(subCategory) || false}
                              icon={<CheckDefaultIcon />}
                              checkedIcon={<CheckSelectedIcon />}
                              onChange={(event) => {
                                handleSubCategoryChange(currentCategory, subCategory, event.target.checked)
                              }}
                            />
                            <ListItemText 
                              primary={subCategory} 
                              sx={{
                                whiteSpace: 'pre-line'
                              }}
                            />
                          </MenuItem>
                        ))}
                      </>
                      )
                    }
                  </Stack>
                </Menu>
              </Stack>

              {/* 篩選搜尋關鍵字 */}
              <Stack
                sx={{
                  width: '270px',
                  padding: '0',
                  display: 'flex',
                  flexFlow: 'row wrap',
                  alignItems: 'center',
                  border: '1px solid #E5E5E5',
                  borderRadius: '8px',
                  background: '#FFFFFF',
                  ml: '8px',
                  '&:hover': {
                    background: '#FAFAFA'
                  },
                  '&:focus': {
                    background: '#FFFFFF',
                    border: '1px solid #52697A'
                  }
                }}
              >
                <TextField 
                  variant="standard" 
                  value={searchValue}
                  hiddenLabel
                  placeholder='輸入搜尋內容'
                  onChange={handleSearchValueChange}
                  disabled={isLoading || isFetchingCaseChunkData}
                  sx={{ 
                    flex: 1,
                    height: '45px',
                    p: '10px 10px 10px 20px',
                    '& .MuiInputBase-root': {
                      height: '100%',
                      backgroundColor: 'transparent',
                      fontSize: '14px',
                      '&::before': {
                        borderBottom: 'none',
                      },
                      '&:hover': {
                        backgroundColor: 'transparent',
                        '&::before': {
                          borderBottom: 'none',
                        },
                      },
                      '&.Mui-disabled': {
                        '&::before': {
                          borderBottom: 'none',
                        },
                      }
                    },
                    '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
                      borderBottom: 'none',
                      backgroundColor: 'transparent',
                      'input:-internal-autofill-selected': {
                        backgroundColor: 'transparent !important'
                      }
                    },
                    '& .MuiInput-root::after': {
                      borderBottom: 'none'
                    },
                    '& .MuiInput-root:-webkit-autofill': {
                      backgroundColor: 'transparent'
                    }
                  }} 
                />
                <Box
                  sx={{
                    display: 'flex',
                    padding: '0 20px 0 0',
                    cursor: 'pointer'
                  }}
                >
                  <SearchIcon 
                    onClick={() => handleSearchChange(searchValue)} 
                  />
                </Box>
              </Stack>

              <Button
                onClick={handleSearch}
                disabled={isLoading || isFetchingCaseChunkData || disabledSearch || searchValue !== '' || selectedTags.length === 0}
                sx={{
                  width: 'auto',
                  background: '#D7DFE4',
                  border: '1px solid transparent',
                  color: '#0A0A0A',
                  padding: '8px 20px',
                  margin: '4px 8px',
                  fontWeight: 400,
                  borderRadius: '99px',
                  justifyContent: 'space-between',
                  '&:hover': {
                    background: '#CFD8DF',
                  },
                  '&:focus': {
                    background: '#D7DFE4',
                    border: '1px solid #ACBCC8'
                  }
                }}
              >
                查詢
              </Button>

              <Button
                onClick={() => {
                  refetchReportCasesExcelData()
                  setTriggerFromOtherPage(false)
                  setIsReportExcelLoading(true)
                }}
                disabled={isLoading || isFetchingCaseChunkData || isReportExcelLoading}
                sx={{
                  width: 'auto',
                  background: '#FFFFFF',
                  border: '1px solid #ACBCC8',
                  color: '#0A0A0A',
                  padding: '8px 20px',
                  fontWeight: 'bold',
                  borderRadius: '99px',
                  justifyContent: 'space-between',
                  '&:hover': {
                    background: '#F0F3F5',
                  },
                  '&:focus': {
                    background: '#F0F3F5',
                    border: '1px solid #52697A'
                  }
                }}
              >
                <span style={{ marginRight: '4px' }}>匯出</span>
                {isReportExcelLoading ? <CircularProgress size={20} /> : <ExportIcon fill='#0A0A0A' />}
              </Button>
            </Box>

            {/* 已選取標籤區塊 */}
            {selectedTags.length !== 0 &&
              <Stack
                sx={{
                  m: '8px 20px 0 20px',
                  pr: '30px',
                  backgroundColor: '#F5F5F5',
                  borderRadius: '4px',
                  display: 'flex',
                  flexFlow: 'row wrap',
                  alignItems: 'center',
                  position: 'relative',
                  transition: 'all .3s',
                  height: tagAreaExpand ? 'auto' : '40px',
                  overflowY: tagAreaExpand ? 'unset' : 'hidden'
                }}
              >
                {selectedTags?.map((item) => {
                  let icon
                  if (item.type === 'ownerUnit') {
                    icon = <TagOwnerUnitIcon fill='#E95076' />
                  } else if (item.type === 'category') {
                    icon = <TagCategoryIcon fill='#E95076' />
                  } else if (item.type === 'searchInclude') {
                    icon = <SearchIncludeIcon fill='#E95076' />
                  }

                  return (
                    <ListItem key={item.tag}>
                      <Chip
                        icon={icon}
                        deleteIcon={<TagDeleteIcon fill='#737373' />}
                        label={item.tag}
                        onDelete={handleDeleteTag(item.tag)}
                        sx={{
                          p: '4px 8px',
                          backgroundColor: '#FDEDF1',
                          borderRadius: '4px',
                          border: '1px solid #F4A4B8',
                          fontSize: '14px',
                          lineHeight: '21px',
                          fontWeight: 'bold',
                          transition: 'all .3s',
                          '&.MuiChip-root': {
                            display: 'flex',
                            flexFlow: 'row wrap',
                            alignItems: 'center',
                            transition: 'all .3s',
                            svg: {
                              margin: 0
                            },
                            span: {
                              padding: '0 4px'
                            },
                            '&:hover': {
                              border: '1px solid #E92153',
                              svg: {
                                fill: '#E92153'
                              }
                            }
                          },
                        }}
                      />
                    </ListItem>
                  )
                })}
                <Button
                  sx={{
                    width: 'auto',
                    background: '#FAFAFA',
                    color: '#E95050',
                    padding: '4px 8px',
                    fontWeight: 400,
                    borderRadius: '4px',
                    justifyContent: 'space-between',
                    '&:hover': {
                      background: '#FAFAFA'
                    },
                  }}
                  onClick={handleResetTags}
                >
                  全部重設
                </Button>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '4px',
                    right: '4px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setTagAreaExpand(!tagAreaExpand)}
                >
                  {
                    tagAreaExpand ? <TagCollapseIcon /> : <TagExpandIcon />
                  }
                </Box>
              </Stack>
            }
          </Stack>

          {/* 分析圖 */}
          <Stack
              sx={{
              p: '20px 20px 0 20px'
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              textColor="secondary"
              indicatorColor="transparent"
              aria-label="secondary tabs example"
              sx={{
                backgroundColor: (theme) => theme.palette.bg.grey,
                width: 'fit-content',
                padding: '4px',
                marginBottom: '20px',
                borderRadius: '99px',
                '&.MuiTabs-root': {
                  minHeight: 'auto'
                }
              }}
            >
              <CustomTab value="trend" label="趨勢分析" />
              <CustomTab value="twoPeriod" label="前後期分析" />
              <CustomTab value="wordCloud" label="關鍵字文字雲" />
            </Tabs>

            {/* 無搜尋結果 */}
            {tableData?.length === 0 && !(isLoading || isFetchingCaseChunkData) && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexFlow: 'column wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 'calc(100vh - 340px)'
                }}
              >
                <DataEmptyIcon />
                <Typography 
                  variant='h3'
                  sx={{
                    color: '#52697A',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    mt: '12px'
                  }}
                >
                  無搜尋結果
                </Typography>
              </Box>
            )}

            <Box 
              sx={{ 
                width: '100%',
              }}
            >
              {(isLoading || isFetchingCaseChunkData) && (value === 'trend' || value === 'twoPeriod') &&
                <Skeleton 
                  variant="rectangular" 
                  sx={{
                    width: 'calc(100%)',
                    height: '350px',
                    margin: 'auto',
                    borderRadius: '20px',
                    backgroundColor: '#bbb'
                  }}
                  animation='pulse'
                  animationspeed='slow'
                />
              }
              {(isLoadingPreviousWordCloudData || isLoadingWordCloudData || isFetchingPreviousWordCloudData || isFetchingWordCloudData) && value === 'wordCloud' &&
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    justifyContent: 'center',
                    mb: '30px'
                  }}
                >
                  <Skeleton 
                    variant="circular" 
                    sx={{
                      width: '40%',
                      height: '350px',
                      margin: '20px auto',
                      backgroundColor: '#bbb'
                    }}
                    animation='pulse'
                    animationspeed='slow'
                  />
                  <Skeleton 
                    variant="circular" 
                    sx={{
                      width: '40%',
                      height: '350px',
                      margin: '20px auto',
                      backgroundColor: '#bbb'
                    }}
                    animation='pulse'
                    animationspeed='slow'
                  />
                </Box>
              }
              {/* 趨勢分析 */}
              {caseData && caseData.totalCount !== 0 && !(isLoading || isFetchingCaseChunkData) && value === 'trend' && (
                <>
                  <Typography
                    variant='h5'
                    sx={{
                      textAlign: 'right',
                      p: '0 10px',
                      fontWeight: 400
                    }}
                  >
                    <span 
                      style={{ 
                        width: '12px',
                        height: '12px',
                        backgroundColor: '#5084E9',
                        display: 'inline-block',
                        borderRadius: '50%',
                        marginRight: '4px',
                        fontWeight: 400
                      }}
                    />
                    當期 
                    <span style={{ color: '#5084E9', margin: '4px', fontWeight: 700 }}>{caseData?.totalCount.toLocaleString()}</span> 
                    筆
                  </Typography>
                  <LineChart
                    categories={Object.keys(currentPeriodData).map((date) => date)}
                    series={[
                      {
                        name: '案件趨勢',
                        data: Object.values(currentPeriodData),
                        color: '#5084E9',
                        lineWidth: 1.5,
                      },
                    ]}
                  />
                </>
              )}

              {/* 前後期分析 */}
              {caseData && caseData.totalCount !== 0 && !(isLoading || isFetchingCaseChunkData) && value === 'twoPeriod' && (
                <>
                  <Box>
                    <Typography
                      variant='h5'
                      sx={{
                        textAlign: 'right',
                        p: '0 10px',
                        fontWeight: 400
                      }}
                    >
                      <span 
                        style={{ 
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#52697A',
                          display: 'inline-block',
                          borderRadius: '50%',
                          marginRight: '4px'
                        }}
                      />
                      前期 
                      <span style={{ color: '#52697A', margin: '4px', fontWeight: 700 }}>{previousPeriodTotalCount?.toLocaleString()}</span> 
                      筆
                    </Typography>
                    <Typography
                      variant='h5'
                      sx={{
                        textAlign: 'right',
                        p: '0 10px',
                        fontWeight: 400
                      }}
                    >
                      <span 
                        style={{ 
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#5084E9',
                          display: 'inline-block',
                          borderRadius: '50%',
                          marginRight: '4px'
                        }}
                      />
                      當期 
                      <span style={{ color: '#5084E9', margin: '4px', fontWeight: 700 }}>{caseData?.totalCount.toLocaleString()}</span> 
                      筆
                    </Typography>
                  </Box>
                  <ColumnChart
                    categories={transformedColumnChartCategories(previousPeriodData, currentPeriodData).categoriesCombine}
                    series={[{
                        name: '前期',
                        data: transformedColumnChartCategories(previousPeriodData, currentPeriodData).previousSeriesData,
                        pointPlacement: -0.03,
                        color: '#52697A'
                    }, {
                        name: '當期',
                        data: transformedColumnChartCategories(previousPeriodData, currentPeriodData).currentSeriesData,
                        pointPlacement: 0.03,
                        color: '#5084E9'
                    }]}
                  />
                </>
              )}

              {/* 文字雲 */}
              {value === 'wordCloud' && caseData.totalCount !== 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    justifyContent: 'center',
                    mb: '30px'
                  }}
                >
                  {previousWordCloudData && previousWordCloudData.data.data.length !== 0 && !(isLoadingPreviousWordCloudData || isLoadingWordCloudData || isFetchingPreviousWordCloudData || isFetchingWordCloudData) && (
                    <Box
                      sx={{
                        width: 'calc(100% / 2)'
                      }}
                    >
                      <WordCloudChart 
                        data={previousWordCloudData.data.data} 
                        chartOptionOverrides={{
                          series: [
                            {
                              type: "wordcloud",
                              data: previousWordCloudData.data.data.map((d, ind) => ({
                                color: previousColors[ind] || '#A0A7BC',
                                ...d,
                              })),
                              rotation: {
                                from: 0,
                                to: 0,
                              },
                              spiral: "archimedean",
                            },
                          ]
                        }}
                      />
                      <Typography 
                        variant='h5'
                        sx={{
                          color: '#52697A',
                          fontWeight: 700,
                          textAlign: 'center',
                          letterSpacing: '.05rem'
                        }}
                      >
                        {`${previousFormattedMinDate} ~ ${previousFormattedMaxDate.split('/')[1]}/${previousFormattedMaxDate.split('/')[2]}`}
                      </Typography>
                    </Box>
                  )}
                  {wordCloudData && wordCloudData.data.data && !(isLoadingPreviousWordCloudData || isLoadingWordCloudData || isFetchingPreviousWordCloudData || isFetchingWordCloudData) && (
                    <Box
                      sx={{
                        width: 'calc(100% / 2)'
                      }}
                    >
                      <WordCloudChart 
                        data={wordCloudData.data.data} 
                        chartOptionOverrides={{
                          series: [
                            {
                              type: "wordcloud",
                              data: wordCloudData.data.data.map((d, ind) => ({
                                color: currentColors[ind] || '#A0A7BC',
                                ...d,
                              })),
                              rotation: {
                                from: 0,
                                to: 0,
                              },
                              spiral: "archimedean",
                            },
                          ]
                        }}
                      />
                      <Typography 
                        variant='h5'
                        sx={{
                          color: theme => theme.palette.primary.main,
                          fontWeight: 700,
                          textAlign: 'center',
                          letterSpacing: '.05rem'
                        }}
                      >
                        {`${formattedMinDate} ~ ${formattedMaxDate.split('/')[1]}/${formattedMaxDate.split('/')[2]}`}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Stack>

          {/* 表格 */}
          <Box 
            sx={{ 
              height: 'auto', 
              width: '100%',
              padding: '0 20px 20px 20px',
              // position: 'relative'
            }}
          >
            {(isLoading || isFetchingCaseChunkData) && <DataGridSkeleton />}
            {
              (tableData && tableData?.length !== 0 || (caseData && caseData?.responseData?.length !== 0)) && !(isLoading || isFetchingCaseChunkData) &&
              <CustomDataGrid
                rows={(tableData && tableData?.length !== 0 && transformData(tableData)) || transformData(caseData?.responseData)}
                // getRowId={(row) => row.id}
                columns={columns.map((column) => ({
                  ...column,
                  renderHeader: (params) => {
                    if (column.sortable) {
                      return (
                        <>
                          {column.headerName}
                          <CustomHeader
                            field={params.field}
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={handleSortModelChange}
                            pagination={pagination}
                            setPagination={setPagination}
                            setSearchAfterFirstParam={setSearchAfterFirstParam}
                            setSearchAfterParam={setSearchAfterParam}
                            setChunkIndex={setChunkIndex}
                          />
                        </>
                      )
                    } else if (column.renderHeader){
                      return column.renderHeader()
                    }
                  },
                }))}
                // pagination={pagination}
                // onPageChange={handlePageChange}
                sortingMode="server"
                paginationModel={pagination}
                onPaginationModelChange={handlePageChange}
                disableColumnMenu
                hideFooterPagination
                disableRowSelectionOnClick
                getRowClassName={getRowClassName}
                getRowHeight={() => 52}
                // sortModel={sortModel}
                // onSortModelChange={handleSortModelChange}
              />
            }
            {caseData?.totalCount > 0 && (
              <Stack 
                direction="row" 
                spacing={2} 
                alignItems="center" 
                sx={{
                  justifyContent: 'center',
                  mt: isLoading || isFetchingCaseChunkData ? '20px' : '-30px'
                }}
              >
                <Pagination
                  count={Math.ceil(caseData?.totalCount / pagination.pageSize)}
                  page={pagination.page + 1}
                  onChange={handlePageChange}
                  sx={{
                    '.MuiButtonBase-root': {
                      color: (theme) => theme.palette.neutral.grey,
                      width: '28px',
                      height: '28px',
                      borderRadius: '4px',
                      minWidth: '28px',
                      margin: '0 4px',
                      '&.Mui-selected': {
                        backgroundColor: 'transparent',
                        color: '#52697A',
                        fontWeight: 'bold'
                      }
                    },
                  }}
                />
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 400
                    }}
                  >
                    第
                    <TextField
                      variant="outlined"
                      value={pagination.page + 1}
                      onChange={handleJumpToPage}
                      size="small"
                      sx={{
                        verticalAlign: 'middle',
                        '& .MuiInputBase-root': {
                          width: '48px',
                          height: '28px',
                          padding: '0 12px',
                          borderRadius: '4px',
                          margin: '0 12px',
                          '.MuiInputBase-input': {
                            textAlign: 'right'
                          }
                        }
                      }}
                    />
                    / 
                    <Box
                      sx={{
                        display: 'inline-block',
                        fontWeight: 'normal',
                        margin: '0 8px 0 4px'
                      }}
                    >
                      { Math.ceil(caseData?.totalCount / pagination.pageSize) }
                    </Box>
                    頁
                  </Typography>
                </Box>
              </Stack>
            )}
          </Box>
        </Paper>
        
        {showAlert && (isErrorCaseFirstChunkData || isErrorWordCloudData || isErrorPreviousWordCloudData) && (
          <StyledAlert severity="error">{errorMessage}</StyledAlert>
        )}
      </>
    )
  }

  export default CaseAnalysis
import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import customTheme from '@/utils/customTheme'
import DashboardLayout from '@/layouts/DashboardLayout'
import Login from '@/pages/Login'
import Home from '@/pages/Home'
import CaseAnalysis from '@/pages/CaseAnalysis'
import CaseDetail from '@/pages/CaseDetail'
import RankStatistics from '@/pages/RankStatistics'
import SmartSummary from '@/pages/SmartSummary'
import ReportContent from '@/pages/ReportContent'

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route index element={<Home />} />
            <Route path="case-analysis" element={<CaseAnalysis />} />
            <Route path="case-analysis/caseId/:caseId" element={<CaseDetail />} />
            <Route path="rank-statistics" element={<RankStatistics />} />
            <Route path="smart-summary" element={<SmartSummary />} />
            <Route path="smart-summary/:weekParam" element={<ReportContent />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
